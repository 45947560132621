<template>
  <div>
    <div
      class="menuItem"
      :key="key"
      v-for="(item, key) in menu"
      :class="{ highlighted: item.highlighted }"
    >
      <div v-if="item.unauthorized" @click="alert('請開通服務後，才可使用此功能')">
        <div :id="item.id" class="w-100 button">
          <span class="serif">{{ item.name }}</span>
          <small v-show="item.notes">{{ item.notes }}</small>
        </div>
      </div>
      <div :id="item.id" v-else-if="item.clickEvent" class="w-100 button" @click="item.onClick">
        <span class="serif">{{ item.name }}</span>
        <small v-show="item.notes">{{ item.notes }}</small>
      </div>
      <a v-else-if="item.externalLink" :href="item.target" target="_blank">
        <div :id="item.id" class="w-100 button">
          <span class="serif">{{ item.name }}</span>
          <small v-show="item.notes">{{ item.notes }}</small>
        </div>
      </a>
      <router-link v-else :to="{ path: item.target }">
        <div :id="item.id" class="w-100 button">
          <span class="serif">{{ item.name }}</span>
          <small v-show="item.notes">{{ item.notes }}</small>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['menu'],
  components: {},
  data() {
    return {};
  },
  methods: {
    alert(msg) {
      alert(msg);
    },
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.menuItem {
  width: 50%;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
}
.highlighted {
  width: 100%;
  .button {
    padding: 20px;
    align-items: flex-end;
    font-size: 130%;
    justify-content: start;
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-blend-mode: luminosity;
    background-color: @highlight;
    overflow: hidden;
    position: relative;
    &#plan {
      background-image: url('../assets/bg_plan.png');
    }
    &#accidentGuide {
      background-image: url('../assets/bg_accidentGuide.png');
    }
    &#call110 {
      background-image: url('../assets/bg_call110.png');
    }
    &#reservation {
      background-image: url('../assets/bg_reservation.png');
    }
    &#insurance {
      background-image: url('../assets/bg_insurance.png');
    }
    &#checkpoints {
      background-image: url('../assets/bg_checkpoints.png');
    }
    &#cardwithcar {
      background-image: url('../assets/bg_cardwithcar.png');
    }
    &#contract {
      background-image: url('../assets/bg_contract.png');
    }
    &#newContract {
      background-image: url('../assets/bg_newContract.png');
    }
    &#editContract {
      background-image: url('../assets/bg_editContract.png');
    }
    &:hover {
      background-color: darken(@highlight, 10%);
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: #da9f64;
      background: linear-gradient(90deg, rgba(180, 157, 95, 0) 0%, #da9f64 65%, #da9f64 100%);
    }
  }
}
.button {
  font-weight: bold;
  background-color: @dark-grey;
  color: white;
  border-radius: 30px;
  border: none;
  text-align: center;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: darken(@dark-grey, 10%);
  }
  span {
    z-index: 1;
  }
  small {
    z-index: 1;
    color: white;
    opacity: 0.7;
    font-size: 12px;
  }
}
</style>
