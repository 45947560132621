<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <div class="head mb-5">
          <div class="justify-content-center row">
            <div class="col-lg-7 p-0">
              <ProgressHeader :currentStep="0" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center" v-show="step == 1">
          <div class="col-lg-7">
            <p class="text-center">輸入會員手機號碼</p>
            <div class="row">
              <div class="col">
                <b-form-input
                  id="mobile"
                  type="tel"
                  class="mb-2"
                  placeholder="手機號碼"
                  v-model="$v.mobile.$model"
                  :state="validateState('mobile')"
                />
                <b-form-invalid-feedback id="mobile">必填</b-form-invalid-feedback>
              </div>
            </div>
            <div
              :class="{ disabled: verifyingMobile || blocklist }"
              class="mt-3"
              @click="onSubmitMobile"
            >
              <Button name="繼續" />
            </div>
            <div class="col">
              <img @click="videoModalShow = true" src="@/assets/login_intro_banner.png" />
            </div>
            <!-- <div class="my-4 text-center" @click="videoModalShow = true">
              <span class="videoModalButton">1分鐘了解車禍線上律師</span>
            </div> -->
          </div>
        </div>
        <div class="row justify-content-center" v-show="step == 2">
          <div class="col-lg-7">
            <p class="text-center">輸入6位數驗證碼</p>
            <div class="row">
              <div class="col">
                <b-form-input
                  type="tel"
                  class="mb-2"
                  placeholder="6位數驗證碼"
                  v-model="$v.code.$model"
                  maxlength="6"
                  :state="validateState('code')"
                />
              </div>
            </div>
            <div :class="{ disabled: verifyingMobile }" class="mt-2" @click="onSubmitCode">
              <Button name="繼續" />
            </div>
            <div class="row mt-3">
              <div class="col">
                <b-link @click="step = 1"><b-icon icon="chevron-left"></b-icon>更改手機號碼</b-link>
              </div>
              <div class="col-auto">
                <b-link id="countdown" class="disabled" @click="onSubmitMobile"></b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VideoModal v-if="videoModalShow" :onClose="closeVideoModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { auth, plan } from '@/mixins';
import Button from '@/elements/Button.vue';
import ProgressHeader from '@/components/ProgressHeader.vue';
import VideoModal from '@/components/VideoModal.vue';

export default {
  mixins: [auth, plan, validationMixin],
  validations: {
    mobile: { required },
    code: { required },
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.profile,
      partnerAuthData: (state) => state.auth.partnerAuthData,
      verifyingMobile: (state) => state.auth.requesting,
    }),
  },
  data() {
    return {
      videoModalShow: false,
      step: 1,
      mobile: '',
      code: '',
      blocklist: false,
      blockedNums: [
        '0917666979',
        '0911261421',
        '0919271105',
        '0906691175',
        '0919119265',
        '0975705131',
        '0960752158',
      ],
    };
  },
  components: {
    Button,
    VideoModal,
    ProgressHeader,
  },
  mounted() {
    if (this.$route.query.auth) {
      this.partnerAuth({
        authToken: this.$route.query.auth,
        onSuccess: () => {
          this.mobile = this.partnerAuthData.userData.phoneNumber;
        },
        onFailed: (err) => {
          alert(err.message);
        },
      });
    }
  },
  watch: {
    mobile: {
      handler() {
        if (this.blockedNums.includes(this.mobile)) {
          this.blocklist = true;
          alert('您的帳號受到限制，請與PAMO聯絡');
        }
      },
    },
  },
  methods: {
    closeVideoModal() {
      this.videoModalShow = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmitMobile() {
      this.$v.$touch();
      if (this.$v.mobile.$error) {
        return;
      }

      this.userLogin({
        mobile: this.mobile,
        onSuccess: () => {
          this.$v.$reset();
          this.step = 2;
        },
        onFailed: () => {
          alert('發生錯誤，請再試一次');
        },
      });
      this.setTimer();
    },
    setTimer() {
      let timeleft = 50;
      const downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          document.getElementById('countdown').innerHTML = '重新發送驗證碼';
          document.getElementById('countdown').classList.remove('disabled');
        } else {
          document.getElementById('countdown').innerHTML = `${timeleft} 秒後重新發送驗證碼`;
          document.getElementById('countdown').classList.add('disabled');
        }
        timeleft -= 1;
      }, 1000);
    },
    onSubmitCode() {
      this.$v.$touch();
      if (this.$v.code.$error) {
        return;
      }

      this.verifyMobile({
        id: this.profile.id,
        code: this.code,
        onSuccess: () => {
          if (this.$route.query.auth) {
            if (this.profile.nationalId && (this.profile.licenses || []).length === 0) {
              // 有註冊過但還沒有序號，自動綁定
              this.addPlan({
                licenseKey: this.partnerAuthData.userData.licenseKey,
                onSuccess: () => {
                  this.$router.replace('/');
                  console.log('a');
                },
                onFailed: () => {
                  alert('綁定序號時發生錯誤，請聯繫客服。');
                },
              });
            } else {
              this.$router.replace(`/?auth=${this.$route.query.auth}`);
              console.log('b');
            }
          } else {
            this.$router.replace('/');
            console.log('c');
          }
        },
        onFailed: () => {
          alert('驗證碼錯誤，請再試一次');
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/lessjs/variables.less';
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.videoModalButton {
  color: @highlight;
  font-weight: bold;
  text-decoration: underline;
}
</style>
