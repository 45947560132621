<template>
  <div class="modal-container">
    <div class="modal-wrapper">
      <div class="modal-card">
        <img src="../assets/plan_point_header.png" />
        <div class="modal-text p-4 text-center">
          <div class="row align-items-center justify-content-center">
            <div class="col-auto pr-0"><h4 class="mb-1 serif">什麼是&nbsp;</h4></div>
            <div class="col-md-7 col-7 pl-0">
              <img src="../assets/plan_point_logo.png" />
            </div>
          </div>
          <p>
            PAMO POINT 可用來折抵
            <a href="https://office.pamolaw.com/" target="_blank">PAMO 事務所</a
            ><br />車禍以外的案件費用！
          </p>
          <div class="row mb-5">
            <div class="col-4">
              <img src="../assets/plan_point_badge_1.png" />
              <div class="plan_point_badge_label">書狀撰寫</div>
            </div>
            <div class="col-4">
              <img src="../assets/plan_point_badge_2.png" />
              <div class="plan_point_badge_label">擬定出庭策略</div>
            </div>
            <div class="col-4">
              <img src="../assets/plan_point_badge_3.png" />
              <div class="plan_point_badge_label">各項法律服務</div>
            </div>
          </div>
          <div class="row mb-4 align-items-center justify-content-center">
            <div class="col-auto pr-0"><h4 class="mb-1 serif">如何使用&nbsp;</h4></div>
            <div class="col-md-7 col-7 pl-0">
              <img src="../assets/plan_point_logo.png" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-6">
              <h1 class="serif">1</h1>
              <div>進入LINE描述您需要的服務</div>
            </div>
            <div class="col-6">
              <h1 class="serif">2</h1>
              <div>我們會仔細說明處理建議</div>
            </div>
          </div>
          <div class="note">實體法律服務費為每小時新台幣4500元起，可使用PAMO POINT來折抵費用</div>
          <div class="note">PAMO POINT 點數有效期間等同會員期間。</div>
          <div v-if="profile.license">
            <a href="https://lin.ee/fparSMT" target="_blank">
              <Button name="查詢/使用我的 PAMO POINT" isHighlighted="true" class="mb-4" />
            </a>
          </div>
          <div v-else>
            <div @click="$router.push('payment/order')">
              <Button name="啟用會籍即贈1200點" isHighlighted="true" class="mb-4" />
            </div>
          </div>
        </div>
        <div v-if="profile.license">
          <div v-show="!profile.license.orderId" class="modal-text p-4 text-center">
            <h1 class="mb-4">綁卡續約不漏點<br />需要用時不嫌少</h1>
            <div class="row align-items-center mb-4">
              <div class="col-md col-12">
                <div class="row">
                  <div class="col-6 col-md-12">
                    <h4>無痛綁卡</h4>
                    <p>每年自動續約 累積的點數不會因為忘記續約而遺失</p>
                  </div>
                  <div class="col-6 col-md-12">
                    <h4>點數累積</h4>
                    <p>每年的 PAMO Point 可以累積跟轉讓</p>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col"><img src="../assets/plan_renew.png" /></div>
            </div>
            <div @click="$router.push('payment/order')">
              <Button name="馬上綁卡續約" isHighlighted="true" class="mb-4" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-close-button clickable" @click="onClose"><b-icon icon="x-lg" /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/css/swiper.css';
import Button from '@/elements/Button.vue';

export default {
  props: ['onClose'],
  components: {
    Button,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState({
      profile: (state) => state.auth.profile,
    }),
  },
  mounted() {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  },
  destroyed() {
    document.body.style.position = '';
    document.body.style.width = '';
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.742);
  color: @dark-grey;
}

.modal-wrapper {
  height: 100%;
  overflow: auto;
  padding: 15px;
}

.modal-card {
  max-width: 500px;
  width: 100%;
  min-height: 100%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;
}

.modal-close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.352);
  border: 2px solid grey;
  border-radius: 50%;
}

.plan_point_badge_label {
  white-space: nowrap;
  text-align: center;
}

.note {
  padding: 10px;
  padding-left: 20px;
  background-color: grey;
  color: white;
  border-radius: 10px;
  font-size: 0.8em;
  text-align: left;
  position: relative;
  margin: 20px 0;
}

.note::before {
  content: '!';
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
}
</style>
