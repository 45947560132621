var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-card"},[_c('img',{attrs:{"src":require("../assets/plan_point_header.png")}}),_c('div',{staticClass:"modal-text p-4 text-center"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"note"},[_vm._v("實體法律服務費為每小時新台幣4500元起，可使用PAMO POINT來折抵費用")]),_c('div',{staticClass:"note"},[_vm._v("PAMO POINT 點數有效期間等同會員期間。")]),(_vm.profile.license)?_c('div',[_c('a',{attrs:{"href":"https://lin.ee/fparSMT","target":"_blank"}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"查詢/使用我的 PAMO POINT","isHighlighted":"true"}})],1)]):_c('div',[_c('div',{on:{"click":function($event){return _vm.$router.push('payment/order')}}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"啟用會籍即贈1200點","isHighlighted":"true"}})],1)])]),(_vm.profile.license)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.profile.license.orderId),expression:"!profile.license.orderId"}],staticClass:"modal-text p-4 text-center"},[_vm._m(5),_vm._m(6),_c('div',{on:{"click":function($event){return _vm.$router.push('payment/order')}}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"馬上綁卡續約","isHighlighted":"true"}})],1)])]):_vm._e()]),_c('div',{staticClass:"modal-close-button clickable",on:{"click":_vm.onClose}},[_c('b-icon',{attrs:{"icon":"x-lg"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-auto pr-0"},[_c('h4',{staticClass:"mb-1 serif"},[_vm._v("什麼是 ")])]),_c('div',{staticClass:"col-md-7 col-7 pl-0"},[_c('img',{attrs:{"src":require("../assets/plan_point_logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" PAMO POINT 可用來折抵 "),_c('a',{attrs:{"href":"https://office.pamolaw.com/","target":"_blank"}},[_vm._v("PAMO 事務所")]),_c('br'),_vm._v("車禍以外的案件費用！ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("../assets/plan_point_badge_1.png")}}),_c('div',{staticClass:"plan_point_badge_label"},[_vm._v("書狀撰寫")])]),_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("../assets/plan_point_badge_2.png")}}),_c('div',{staticClass:"plan_point_badge_label"},[_vm._v("擬定出庭策略")])]),_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("../assets/plan_point_badge_3.png")}}),_c('div',{staticClass:"plan_point_badge_label"},[_vm._v("各項法律服務")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-4 align-items-center justify-content-center"},[_c('div',{staticClass:"col-auto pr-0"},[_c('h4',{staticClass:"mb-1 serif"},[_vm._v("如何使用 ")])]),_c('div',{staticClass:"col-md-7 col-7 pl-0"},[_c('img',{attrs:{"src":require("../assets/plan_point_logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"},[_c('h1',{staticClass:"serif"},[_vm._v("1")]),_c('div',[_vm._v("進入LINE描述您需要的服務")])]),_c('div',{staticClass:"col-6"},[_c('h1',{staticClass:"serif"},[_vm._v("2")]),_c('div',[_vm._v("我們會仔細說明處理建議")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"mb-4"},[_vm._v("綁卡續約不漏點"),_c('br'),_vm._v("需要用時不嫌少")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center mb-4"},[_c('div',{staticClass:"col-md col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-12"},[_c('h4',[_vm._v("無痛綁卡")]),_c('p',[_vm._v("每年自動續約 累積的點數不會因為忘記續約而遺失")])]),_c('div',{staticClass:"col-6 col-md-12"},[_c('h4',[_vm._v("點數累積")]),_c('p',[_vm._v("每年的 PAMO Point 可以累積跟轉讓")])])])]),_c('div',{staticClass:"col-md-7 col"},[_c('img',{attrs:{"src":require("../assets/plan_renew.png")}})])])
}]

export { render, staticRenderFns }