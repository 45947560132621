var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-text p-4"},[_c('div',{staticClass:"row justify-content-center align-items-center text-center"},[_vm._m(0),(!_vm.profile.license)?_c('div',{staticClass:"col-md"},[_vm._m(1),_c('div',{on:{"click":function($event){return _vm.$router.push('payment/order')}}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"立即啟用會籍","isHighlighted":"true"}})],1)]):_vm._e()]),_vm._m(2),_vm._m(3)]),(!_vm.profile.license)?_c('div',{staticClass:"bg-black text-white p-4"},[_c('div',{staticClass:"mb-4 text-center justify-content-center"},[_vm._m(4),_c('img',{staticClass:"w-50 mb-4",attrs:{"src":require("@/assets/plan_logo_2.png")}}),_c('h6',{staticClass:"mb-4 text-gold serif"},[_vm._v("1點=1元｜可折抵其他各類法律案件｜可累積, 無效期")]),_vm._m(5),_c('div',{on:{"click":function($event){return _vm.$router.push('payment/order')}}},[_c('Button',{staticClass:"mb-4 w-100",attrs:{"name":"現省1200元","isHighlighted":"true"}})],1)])]):_c('div',[_c('a',{attrs:{"href":"https://lin.ee/fparSMT","target":"_blank"}},[_c('Button',{attrs:{"name":"開始諮詢","isHighlighted":"true"}})],1)])]),_c('div',{staticClass:"modal-close-button clickable",on:{"click":_vm.onClose}},[_c('b-icon',{attrs:{"icon":"x-lg"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('img',{attrs:{"src":require("../assets/progress_header.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('small',[_vm._v("NT$")]),_vm._v("1,200"),_c('small',[_vm._v("/年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-md-left text-center justify-content-center align-items-center mb-5"},[_c('div',{staticClass:"col-md-4 col-6"},[_c('img',{attrs:{"src":require("../assets/intro_1.png")}})]),_c('div',{staticClass:"col-md col-12"},[_c('h3',{staticClass:"serif"},[_vm._v("不限次數問到飽")]),_c('p',[_vm._v("專家解說車禍處理策略與細節，服務不限時長與次數，問到您清楚明白！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-md-left text-center justify-content-center align-items-center mb-5"},[_c('div',{staticClass:"col-md-4 col-6"},[_c('img',{attrs:{"src":require("../assets/intro_2.png")}})]),_c('div',{staticClass:"col-md col-12"},[_c('h3',{staticClass:"serif"},[_vm._v("不額外收取費用")]),_c('p',[_vm._v(" 會員本人的車禍問題，線上問，真的問到飽！若需實體法律服務 - 車禍或其他各類案件，都將提供您明確報價。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"mb-2 serif"},[_vm._v("現在購買PAMO"),_c('br'),_vm._v("再贈1200點")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-4"},[_vm._v(" 律師費為4500元/小時"),_c('br'),_vm._v(" 由略策法律事務所提供您全方位法律服務 ")])
}]

export { render, staticRenderFns }