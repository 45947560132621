<template>
  <div class="header mb-5 pb-3">
    <div class="back pt-3 pb-3 clickable" v-on:click="$router.replace(backto)">
      <b-icon icon="caret-left-fill" aria-hidden="true"></b-icon><span>返回</span>
    </div>
    <h3 class="mb-2 serif">{{ title }}</h3>
    <small v-show="notes">{{ notes }}</small>
    <div class="">
      <a :href="url" target="_blank">{{ link }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['title', 'backto', 'notes', 'link', 'url'],
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.header {
  border-bottom: 1px solid rgb(90, 90, 90);
}
.back {
  color: @highlight;
}
h3 {
  color: white;
}
</style>
