<template>
  <div class="modal-container">
    <div class="modal-wrapper">
      <div class="modal-card">
        <div class="modal-text p-4">
          <div class="row justify-content-center align-items-center text-center">
            <div class="col-md-6"><img src="../assets/progress_header.png" /></div>
            <div v-if="!profile.license" class="col-md">
              <h1><small>NT$</small>1,200<small>/年</small></h1>
              <div @click="$router.push('payment/order')">
                <Button name="立即啟用會籍" isHighlighted="true" class="mb-4" />
              </div>
            </div>
          </div>
          <div class="row text-md-left text-center justify-content-center align-items-center mb-5">
            <div class="col-md-4 col-6"><img src="../assets/intro_1.png" /></div>
            <div class="col-md col-12">
              <h3 class="serif">不限次數問到飽</h3>
              <p>專家解說車禍處理策略與細節，服務不限時長與次數，問到您清楚明白！</p>
            </div>
          </div>
          <div class="row text-md-left text-center justify-content-center align-items-center mb-5">
            <div class="col-md-4 col-6"><img src="../assets/intro_2.png" /></div>
            <div class="col-md col-12">
              <h3 class="serif">不額外收取費用</h3>
              <p>
                會員本人的車禍問題，線上問，真的問到飽！若需實體法律服務 -
                車禍或其他各類案件，都將提供您明確報價。
              </p>
            </div>
          </div>
        </div>
        <div class="bg-black text-white p-4" v-if="!profile.license">
          <div class="mb-4 text-center justify-content-center">
            <h1 class="mb-2 serif">現在購買PAMO<br />再贈1200點</h1>
            <img class="w-50 mb-4" src="@/assets/plan_logo_2.png" />
            <h6 class="mb-4 text-gold serif">1點=1元｜可折抵其他各類法律案件｜可累積, 無效期</h6>
            <p class="mb-4">
              律師費為4500元/小時<br />
              由略策法律事務所提供您全方位法律服務
            </p>
            <div @click="$router.push('payment/order')">
              <Button name="現省1200元" isHighlighted="true" class="mb-4 w-100" />
            </div>
          </div>
        </div>
        <div v-else>
          <a href="https://lin.ee/fparSMT" target="_blank">
            <Button name="開始諮詢" isHighlighted="true" />
          </a>
        </div>
      </div>
      <div class="modal-close-button clickable" @click="onClose"><b-icon icon="x-lg" /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/css/swiper.css';
import Button from '@/elements/Button.vue';

export default {
  props: ['onClose'],
  components: {
    Button,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState({
      profile: (state) => state.auth.profile,
    }),
  },
  mounted() {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  },
  destroyed() {
    document.body.style.position = '';
    document.body.style.width = '';
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.742);
  color: @dark-grey;
}

.modal-wrapper {
  height: 100%;
  overflow: auto;
  padding: 15px;
}

.modal-card {
  max-width: 500px;
  width: 100%;
  min-height: 100%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;
}

.user-status {
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.modal-close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.352);
  border: 2px solid grey;
  border-radius: 50%;
}

small {
  font-size: 0.7em;
}

.bg-black {
  background-color: black;
}
.text-white {
  color: white;
}

.text-gold {
  color: @highlight;
}
</style>
