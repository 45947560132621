<template>
  <div class="progressheader-bar">
    <div class="progressheader-logo">
      <img src="@/assets/progress_header.png" />
    </div>
    <div class="progressheader-steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="step"
        :class="{ completed: index < currentStep, active: index === currentStep }"
      >
        <div
          :id="`step-${index + 1}`"
          class="step-circle"
          :class="{ completed: index < currentStep, active: index === currentStep }"
        ></div>
        <div class="step-label">{{ step }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressHeader',
  props: {
    currentStep: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      steps: ['手機驗證', '填寫會員資料', '填寫付款資料', '成為會員'],
    };
  },
  computed: {
    progressPercentage() {
      return ((this.currentStep / (this.steps.length - 1)) * 100).toFixed(2);
    },
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/lessjs/variables.less';
.progressheader-bar {
  width: 100%;
  box-sizing: border-box;
}

.progressheader-logo {
  width: 250px;
  margin: 0 auto;
}

.progressheader-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  &::before {
    content: ' ';
    width: 80%;
    border-bottom: 2px dotted grey;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 37%;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  &.active::after {
    content: ' ';
    width: 31%;
    height: 31%;
    border-radius: 50%;
    border: 10px solid white;
    position: absolute;
    top: 20%;
    animation: wave 1s infinite;
  }
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

.step-circle {
  width: 70%;
  padding-top: 75%;
  background-position: center;
  background-size: 100%;
  z-index: 1;
  &#step-1 {
    &.active {
      background-image: url('../assets/ic_progress_header_1_active.png');
    }
    &.completed {
      background-image: url('../assets/ic_progress_header_1_complete.png');
    }
  }
  &#step-2 {
    background-image: url('../assets/ic_progress_header_2.png');
    &.active {
      background-image: url('../assets/ic_progress_header_2_active.png');
    }
    &.completed {
      background-image: url('../assets/ic_progress_header_2_complete.png');
    }
  }
  &#step-3 {
    background-image: url('../assets/ic_progress_header_3.png');
    &.active {
      background-image: url('../assets/ic_progress_header_3_active.png');
    }
  }
  &#step-4 {
    background-image: url('../assets/ic_progress_header_4.png');
  }
}

.step-label {
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
}
</style>
