<template>
  <div class="w-100 button" :class="{ highlight: isHighlighted }">
    <span class="name serif">{{ name }}</span>
  </div>
</template>
<script>
export default {
  name: 'Button',
  props: ['name', 'isHighlighted'],
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.button {
  background-color: @dark-grey;
  color: white;
  border: none;
  text-align: center;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  font-size: 1.1em;
  font-weight: 900;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.8);
  .name {
    text-decoration: none !important;
  }
  &.highlight {
    background-color: @highlight;
    &:hover {
      background-color: darken(@highlight, 10%);
    }
  }

  &.disabled {
    background-color: darken(@highlight, 30%);
    color: grey;
    pointer-events: none;
  }

  &:hover {
    background-color: darken(@dark-grey, 10%);
    text-decoration: none !important;
  }
}

.button.highlight::before {
  content: '';
  position: absolute;
  top: 0;
  left: -200%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  transform: skewX(-30deg);
  transition: all 0.3s ease-in-out;
  z-index: 1;
  pointer-events: none;
  animation: shine-animation 4s ease-in-out infinite;
}

.button.highlight:hover::before {
  animation: shine-animation-hover 0.5s linear;
  left: 200%;
}

@keyframes shine-animation-hover {
  0% {
    left: -200%;
  }
  100% {
    left: 200%;
  }
}
@keyframes shine-animation {
  0% {
    left: -350%;
    opacity: 1;
  }
  10% {
    left: 200%;
    opacity: 1;
  }
  11% {
    left: 200%;
    opacity: 0;
  }
  12% {
    left: -350%;
    opacity: 0;
  }
  80% {
    left: -350%;
    opacity: 1;
  }
  90% {
    left: 200%;
    opacity: 1;
  }
  91% {
    left: 200%;
    opacity: 0;
  }
  91% {
    left: -350%;
    opacity: 0;
  }
  100% {
    left: -350%;
    opacity: 0;
  }
}
</style>
